export const UnleashFeatureNames = {
  dstTWJobFilter: 'dstTWJobFilter',
  dstBuilderIntegration: 'dstBuilderIntegration',
  dstFollowUpRequest: 'dstFollowUpRequest',
  dstHideApplicationPageBanner: 'dstHideApplicationPageBanner',
  dstHideApplicationPageArchiveTitle: 'dstHideApplicationPageArchiveTitle',
  dstFollowerNotificationPreference: 'dstFollowerNotificationPreference',
  dstOdyssey: 'dstOdyssey',
  dstIsSupersonicBannerEnabled: 'dstIsSupersonicBannerEnabled',
  dstInterviewProcess: 'dstInterviewProcess',
  dstEnableMobileDownloadBanner: 'dstEnableMobileDownloadBanner',
  dstApplicationStatusTracking: 'dstApplicationStatusTracking',
  dstImproveJobSearchFilter: 'dstImproveJobSearchFilter',
  dstEmptyJobSearchRedirectToFYPCTA: 'dstEmptyJobSearchRedirectToFYPCTA',
  dstShowRecommendationCard: 'dstShowRecommendationCard',
  dstResumeParsingEnabled: 'dstResumeParsingEnabled',
  dstHideJobCategoryFilter: 'dstHideJobCategoryFilter',
  allExperimentsExploreAndForYou: 'allExperimentsExploreAndForYou',
  dstCreateStreamChannel: 'dstCreateStreamChannel',
  dstCompanyNormalisedLocationEnabled: 'dstCompanyNormalisedLocationEnabled',
  dstViewEducationLevelEnabled: 'dstViewEducationLevelEnabled',
  dstChatApplicationStatusEnabled: 'dstChatApplicationStatusEnabled',
  dstNudgeChatWithHiringManager: 'dstNudgeChatWithHiringManager',
  dstWhatsappSupport: 'dstWhatsappSupport',
  dstSearchJobFilterV2: 'dstSearchJobFilterV2',
  dstExperimentOpportunitySkillMatch: 'dstExperimentOpportunitySkillMatch',
  dstExperimentShowLoginPopup: 'dstExperimentShowLoginPopup',
  dstShowApplicationForms: 'dstShowApplicationForms',
  dstHideResumeSectionInApplicationPage:
    'dstHideResumeSectionInApplicationPage',
  dstHideSkillsV2: 'dstHideSkillsV2',
  dstGetJobTitleSuggestionV2: 'dstGetJobTitleSuggestionV2',
  dstExperimentApiProvider: 'dstExperimentApiProvider',
  dstApplyJobV2: 'dstApplyJobV2',
  dstExperimentJobDetailRevising: 'dstExperimentJobDetailRevising',
  dstShowMoreJobRecommendationFilters: 'dstShowMoreJobRecommendationFilters',
  dstExperimentApplicationWarning: 'dstExperimentApplicationWarning',
  dstJobDetailNewUrlChecking: 'dstJobDetailNewUrlChecking',
  dstGetSimilarJobsV2: 'dstGetSimilarJobsV2',
  dstShowApplyOnAppButton: 'dstShowApplyOnAppButton',
  dstForYouV3: 'dstForYouV3',
  dstSearchJobsV3: 'dstSearchJobsV3',
};

export const ExperimentApiVariants = {
  V1: 'V1',
  V2: 'V2',
  V3: 'V3',
} as const;

export const ExperimentApiProviders = {
  AWS: 'AWS',
  ALICLOUD: 'ALICLOUD',
} as const;

export const dstExperimentSearchJob = 'dstExperiementSearchJob';
